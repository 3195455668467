// :root {
//   $font-stack: Helvetica, sans-serif;
//   $font-size: 16px;
//
//   $primary-color: #140a9a; //blue
//   $secondary-color: #fdd700; //yellow fdd700
//   $tertiary-color: #1a1818; //black
//   $light-color: #dddddd;
//   $medium-color: #b8b8b8;
//   $dark-color: #818386;
//
//   $accent-light: #6a8892;
//   $accent-dark: #1f3e49;
//   $accent: #c8c04c;
//   $white-color: #ffffff;
//
//   $dark-font: $tertiary-color;
//   $light-font: $light-color;
// }

$font-stack: Helvetica, sans-serif;
$font-size: 16px;

$primary-color: #a50000; //blue
$secondary-color: #750a0a; //yellow fdd700
$tertiary-color: #356a7c; //black
$light-color: #e8e8e8;
$medium-color: #b9bbbd;
$dark-color: #404041;

$accent-light: #6a8892; //orange
$accent-dark: #1f3e49;
$accent: #c8c04c;//lime
$white-color: #ffffff;

$dark-font: $dark-color;
$light-font: $light-color;
