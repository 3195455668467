.wire-color {
	display: inline-block;

	vertical-align: middle;

	width: 40px;
	height: 15px;
	margin: 0px 15px;
	border: 1px solid #808080;
}

/****************************
*
*	 BRP Wire Colors
*
*****************************/

/****************************
*	 BEIGE
*****************************/

.bg, .be {
	background: rgb(245,245,220);
}

.bgbk {
	background: rgb(245,245,220);
	background: -moz-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(0,0,0,1) 40%, rgba(245,245,220,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(0,0,0,1) 40%, rgba(245,245,220,1) 50%);
	background: linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(0,0,0,1) 40%, rgba(245,245,220,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5dc",endColorstr="#f5f5dc",GradientType=1);
}

.bgbn {
	background: rgb(245,245,220);
	background: -moz-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(102,51,0,1) 40%, rgba(245,245,220,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(102,51,0,1) 40%, rgba(245,245,220,1) 50%);
	background: linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(102,51,0,1) 40%, rgba(245,245,220,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5dc",endColorstr="#663300",GradientType=1);
}

.bgbu {
	background: rgb(245,245,220);
	background: -moz-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(0,0,255,1) 40%, rgba(245,245,220,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(0,0,255,1) 40%, rgba(245,245,220,1) 50%);
	background: linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(0,0,255,1) 40%, rgba(245,245,220,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5dc",endColorstr="#f5f5dc",GradientType=1);
}

.bggn {
	background: rgb(245,245,220);
	background: -moz-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(0,128,0,1) 40%, rgba(245,245,220,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(0,128,0,1) 40%, rgba(245,245,220,1) 50%);
	background: linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(0,128,0,1) 40%, rgba(245,245,220,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5dc",endColorstr="#f5f5dc",GradientType=1);
}

.bggy {
	background: rgb(245,245,220);
	background: -moz-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(128,128,128,1) 40%, rgba(245,245,220,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(128,128,128,1) 40%, rgba(245,245,220,1) 50%);
	background: linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(128,128,128,1) 40%, rgba(245,245,220,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5dc",endColorstr="#f5f5dc",GradientType=1);
}

.bgog {
	background: rgb(245,245,220);
	background: -moz-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,165,0,1) 40%, rgba(245,245,220,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,165,0,1) 40%, rgba(245,245,220,1) 50%);
	background: linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,165,0,1) 40%, rgba(245,245,220,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5dc",endColorstr="#f5f5dc",GradientType=1);
}

.bgpk {
	background: rgb(245,245,220);
	background: -moz-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,192,203,1) 40%, rgba(245,245,220,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,192,203,1) 40%, rgba(245,245,220,1) 50%);
	background: linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,192,203,1) 40%, rgba(245,245,220,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5dc",endColorstr="#f5f5dc",GradientType=1);
}

.bgrd {
	background: rgb(245,245,220);
	background: -moz-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,0,0,1) 40%, rgba(245,245,220,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,0,0,1) 40%, rgba(245,245,220,1) 50%);
	background: linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,0,0,1) 40%, rgba(245,245,220,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5dc",endColorstr="#f5f5dc",GradientType=1);
}

.bgwh, .bewh {
	background: rgb(245,245,220);
	background: -moz-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,255,255,1) 40%, rgba(245,245,220,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,255,255,1) 40%, rgba(245,245,220,1) 50%);
	background: linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,255,255,1) 40%, rgba(245,245,220,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5dc",endColorstr="#f5f5dc",GradientType=1);
}

.bgvt {
	background: rgb(245,245,220);
	background: -moz-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(128,0,128,1) 40%, rgba(245,245,220,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(128,0,128,1) 40%, rgba(245,245,220,1) 50%);
	background: linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(128,0,128,1) 40%, rgba(245,245,220,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5dc",endColorstr="#f5f5dc",GradientType=1);
}

.bgye {
	background: rgb(245,245,220);
	background: -moz-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,255,0,1) 40%, rgba(245,245,220,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,255,0,1) 40%, rgba(245,245,220,1) 50%);
	background: linear-gradient(70deg, rgba(245,245,220,1) 30%, rgba(255,255,0,1) 40%, rgba(245,245,220,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5dc",endColorstr="#f5f5dc",GradientType=1);
}

/****************************
*	 BLACK
*****************************/

.bk {
	background: rgb(0,0,0);
}

.bkbg {
	background: rgb(0,0,0);
	background: -moz-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(245,245,220,1) 40%, rgba(0,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(245,245,220,1) 40%, rgba(0,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(245,245,220,1) 40%, rgba(0,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#663300",GradientType=1);
}

.bkbn {
	background: rgb(0,0,0);
	background: -moz-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(102,51,0,1) 40%, rgba(0,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(102,51,0,1) 40%, rgba(0,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(102,51,0,1) 40%, rgba(0,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#663300",GradientType=1);
}

.bkbu {
	background: rgb(0,0,0);
	background: -moz-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(0,0,255,1) 40%, rgba(0,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(0,0,255,1) 40%, rgba(0,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(0,0,255,1) 40%, rgba(0,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.bkgn {
	background: rgb(0,0,0);
	background: -moz-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(0,128,0,1) 40%, rgba(0,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(0,128,0,1) 40%, rgba(0,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(0,128,0,1) 40%, rgba(0,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.bkog {
	background: rgb(0,0,0);
	background: -moz-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,165,0,1) 40%, rgba(0,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,165,0,1) 40%, rgba(0,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,165,0,1) 40%, rgba(0,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.bkpk {
	background: rgb(0,0,0);
	background: -moz-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,192,203,1) 40%, rgba(0,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,192,203,1) 40%, rgba(0,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,192,203,1) 40%, rgba(0,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}


.bkrd {
	background: rgb(0,0,0);
	background: -moz-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,0,0,1) 40%, rgba(0,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,0,0,1) 40%, rgba(0,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,0,0,1) 40%, rgba(0,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.bkvt {
	background: rgb(0,0,0);
	background: -moz-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(128,0,128,1) 40%, rgba(0,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(128,0,128,1) 40%, rgba(0,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(128,0,128,1) 40%, rgba(0,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.bkwh {
	background: rgb(0,0,0);
	background: -moz-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,255,255,1) 40%, rgba(0,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,255,255,1) 40%, rgba(0,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,255,255,1) 40%, rgba(0,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.bkye {
	background: rgb(0,0,0);
	background: -moz-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,255,0,1) 40%, rgba(0,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,255,0,1) 40%, rgba(0,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,0,1) 30%, rgba(255,255,0,1) 40%, rgba(0,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

/****************************
*	 BROWN
*****************************/

.bn {
	background: rgb(102,51,0);
}

.bnbk {
	background: rgb(102,51,0);
	background: -moz-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(0,0,0,1) 40%, rgba(102,51,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(0,0,0,1) 40%, rgba(102,51,0,1) 50%);
	background: linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(0,0,0,1) 40%, rgba(102,51,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#663300",endColorstr="#663300",GradientType=1);
}

.bnbu {
	background: rgb(102,51,0);
	background: -moz-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(0,0,255,1) 40%, rgba(102,51,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(0,0,255,1) 40%, rgba(102,51,0,1) 50%);
	background: linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(0,0,255,1) 40%, rgba(102,51,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#663300",endColorstr="#663300",GradientType=1);
}

.bngn {
	background: rgb(102,51,0);
	background: -moz-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(0,128,0,1) 40%, rgba(102,51,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(0,128,0,1) 40%, rgba(102,51,0,1) 50%);
	background: linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(0,128,0,1) 40%, rgba(102,51,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#663300",endColorstr="#663300",GradientType=1);
}

.bngy {
	background: rgb(102,51,0);
	background: -moz-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(128,128,128,1) 40%, rgba(102,51,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(128,128,128,1) 40%, rgba(102,51,0,1) 50%);
	background: linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(128,128,128,1) 40%, rgba(102,51,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#663300",endColorstr="#663300",GradientType=1);
}

.bnog {
	background: rgb(102,51,0);
	background: -moz-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,165,0,1) 40%, rgba(102,51,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,165,0,1) 40%, rgba(102,51,0,1) 50%);
	background: linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,165,0,1) 40%, rgba(102,51,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#663300",endColorstr="#663300",GradientType=1);
}

.bnpk {
	background: rgb(102,51,0);
	background: -moz-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,192,203,1) 40%, rgba(102,51,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,192,203,1) 40%, rgba(102,51,0,1) 50%);
	background: linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,192,203,1) 40%, rgba(102,51,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#663300",endColorstr="#663300",GradientType=1);
}

.bnrd, .brrd {
	background: rgb(102,51,0);
	background: -moz-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,0,0,1) 40%, rgba(102,51,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,0,0,1) 40%, rgba(102,51,0,1) 50%);
	background: linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,0,0,1) 40%, rgba(102,51,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#663300",endColorstr="#663300",GradientType=1);
}

.bnwh {
	background: rgb(102,51,0);
	background: -moz-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,255,255,1) 40%, rgba(102,51,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,255,255,1) 40%, rgba(102,51,0,1) 50%);
	background: linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,255,255,1) 40%, rgba(102,51,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#663300",endColorstr="#663300",GradientType=1);
}

.bnye {
	background: rgb(102,51,0);
	background: -moz-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,255,0,1) 40%, rgba(102,51,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,255,0,1) 40%, rgba(102,51,0,1) 50%);
	background: linear-gradient(70deg, rgba(102,51,0,1) 30%, rgba(255,255,0,1) 40%, rgba(102,51,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#663300",endColorstr="#663300",GradientType=1);
}

/****************************
*	 BLUE
*****************************/

.bu {
	background: rgb(0,0,255);
}

.bubk {
	background: rgb(0,0,255);
	background: -moz-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(0,0,0,1) 40%, rgba(0,0,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(0,0,0,1) 40%, rgba(0,0,255,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(0,0,0,1) 40%, rgba(0,0,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0000ff",endColorstr="#0000ff",GradientType=1);
}

.bugn {
	background: rgb(0,0,255);
	background: -moz-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(0,128,0,1) 40%, rgba(0,0,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(0,128,0,1) 40%, rgba(0,0,255,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(0,128,0,1) 40%, rgba(0,0,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0000ff",endColorstr="#0000ff",GradientType=1);
}

.bultbu {
	background: rgb(0,0,255);
	background: -moz-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(173,216,230,1) 40%, rgba(0,0,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(173,216,230,1) 40%, rgba(0,0,255,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(173,216,230,1) 40%, rgba(0,0,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0000ff",endColorstr="#0000ff",GradientType=1);
}

.burd {
	background: rgb(0,0,255);
	background: -moz-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(255,0,0,1) 40%, rgba(0,0,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(255,0,0,1) 40%, rgba(0,0,255,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(255,0,0,1) 40%, rgba(0,0,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0000ff",endColorstr="#0000ff",GradientType=1);
}

.buwh {
	background: rgb(0,0,255);
	background: -moz-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(255,255,255,1) 40%, rgba(0,0,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(255,255,255,1) 40%, rgba(0,0,255,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(255,255,255,1) 40%, rgba(0,0,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0000ff",endColorstr="#0000ff",GradientType=1);
}

.buye {
	background: rgb(0,0,255);
	background: -moz-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(255,255,0,1) 40%, rgba(0,0,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(255,255,0,1) 40%, rgba(0,0,255,1) 50%);
	background: linear-gradient(70deg, rgba(0,0,255,1) 30%, rgba(255,255,0,1) 40%, rgba(0,0,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0000ff",endColorstr="#0000ff",GradientType=1);
}

/****************************
*	 GREEN
*****************************/

.gn {
	background: rgb(0,128,0);
}

.gnbk {
	background: rgb(0,128,0);
	background: -moz-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(0,0,0,1) 40%, rgba(0,128,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(0,0,0,1) 40%, rgba(0,128,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(0,0,0,1) 40%, rgba(0,128,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008000",endColorstr="#008000",GradientType=1);
}

.gnbg {
	background: rgb(0,128,0);
	background: -moz-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(245,245,220,1) 40%, rgba(0,128,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(245,245,220,1) 40%, rgba(0,128,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(245,245,220,1) 40%, rgba(0,128,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008000",endColorstr="#008000",GradientType=1);
}

.gnbu {
	background: rgb(0,128,0);
	background: -moz-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(0,0,255,1) 40%, rgba(0,128,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(0,0,255,1) 40%, rgba(0,128,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(0,0,255,1) 40%, rgba(0,128,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008000",endColorstr="#008000",GradientType=1);
}

.gnbn {
	background: rgb(0,128,0);
	background: -moz-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(102,51,0,1) 40%, rgba(0,128,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(102,51,0,1) 40%, rgba(0,128,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(102,51,0,1) 40%, rgba(0,128,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008000",endColorstr="#008000",GradientType=1);
}

.gngy {
	background: rgb(0,128,0);
	background: -moz-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(128,128,128,1) 40%, rgba(0,128,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(128,128,128,1) 40%, rgba(0,128,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(128,128,128,1) 40%, rgba(0,128,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008000",endColorstr="#008000",GradientType=1);
}

.gnog {
	background: rgb(0,128,0);
	background: -moz-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(255,165,0,1) 40%, rgba(0,128,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(255,165,0,1) 40%, rgba(0,128,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(255,165,0,1) 40%, rgba(0,128,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008000",endColorstr="#008000",GradientType=1);
}

.gnwh {
	background: rgb(0,128,0);
	background: -moz-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(255,255,255,1) 40%, rgba(0,128,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(255,255,255,1) 40%, rgba(0,128,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(255,255,255,1) 40%, rgba(0,128,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0000ff",endColorstr="#0000ff",GradientType=1);
}

.gnye {
	background: rgb(0,128,0);
	background: -moz-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(255,255,0,1) 40%, rgba(0,128,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(255,255,0,1) 40%, rgba(0,128,0,1) 50%);
	background: linear-gradient(70deg, rgba(0,128,0,1) 30%, rgba(255,255,0,1) 40%, rgba(0,128,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008000",endColorstr="#008000",GradientType=1);
}

/****************************
*	 GREY
*****************************/

.gy {
	background: rgb(128,128,128);
}

.gybn {
	background: rgb(128,128,128);
	background: -moz-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(102,51,0,1) 40%, rgba(128,128,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(102,51,0,1) 40%, rgba(128,128,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(102,51,0,1) 40%, rgba(128,128,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#808080",endColorstr="#808080",GradientType=1);
}

.gybg {
	background: rgb(128,128,128);
	background: -moz-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(245,245,220,1) 40%, rgba(128,128,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(245,245,220,1) 40%, rgba(128,128,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(245,245,220,1) 40%, rgba(128,128,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#808080",endColorstr="#808080",GradientType=1);
}

.gybu {
	background: rgb(128,128,128);
	background: -moz-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(0,0,255,1) 40%, rgba(128,128,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(0,0,255,1) 40%, rgba(128,128,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(0,0,255,1) 40%, rgba(128,128,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#808080",endColorstr="#808080",GradientType=1);
}

.gygn {
	background: rgb(128,128,128);
	background: -moz-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(0,128,0,1) 40%, rgba(128,128,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(0,128,0,1) 40%, rgba(128,128,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(0,128,0,1) 40%, rgba(128,128,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#808080",endColorstr="#808080",GradientType=1);
}

.gyog {
	background: rgb(128,128,128);
	background: -moz-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,165,0,1) 40%, rgba(128,128,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,165,0,1) 40%, rgba(128,128,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,165,0,1) 40%, rgba(128,128,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#808080",endColorstr="#808080",GradientType=1);
}

.gypk {
	background: rgb(128,128,128);
	background: -moz-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,192,203,1) 40%, rgba(128,128,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,192,203,1) 40%, rgba(128,128,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,192,203,1) 40%, rgba(128,128,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#808080",endColorstr="#808080",GradientType=1);
}

.gyvt {
	background: rgb(128,128,128);
	background: -moz-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(128,0,128,1) 40%, rgba(128,128,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(128,0,128,1) 40%, rgba(128,128,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(128,0,128,1) 40%, rgba(128,128,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#808080",endColorstr="#808080",GradientType=1);
}

.gywh {
	background: rgb(128,128,128);
	background: -moz-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,255,255,1) 40%, rgba(128,128,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,255,255,1) 40%, rgba(128,128,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,255,255,1) 40%, rgba(128,128,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#808080",endColorstr="#808080",GradientType=1);
}

.gyye {
	background: rgb(128,128,128);
	background: -moz-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,255,0,1) 40%, rgba(128,128,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,255,0,1) 40%, rgba(128,128,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,128,128,1) 30%, rgba(255,255,0,1) 40%, rgba(128,128,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#808080",endColorstr="#808080",GradientType=1);
}

/****************************
*	 LIGHT BLUE
*****************************/

.ltbu {
	background: rgb(173,216,230);
}

.ltbubk {
	background: rgb(173,216,230);
	background: -moz-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(0,0,0,1) 40%, rgba(173,216,230,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(0,0,0,1) 40%, rgba(173,216,230,1) 50%);
	background: linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(0,0,0,1) 40%, rgba(173,216,230,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ADD8E6",endColorstr="#ADD8E6",GradientType=1);
}

.ltbugn {
	background: rgb(173,216,230);
	background: -moz-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(0,128,0,1) 40%, rgba(173,216,230,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(0,128,0,1) 40%, rgba(173,216,230,1) 50%);
	background: linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(0,128,0,1) 40%, rgba(173,216,230,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ADD8E6",endColorstr="#ADD8E6",GradientType=1);
}

.ltbuog {
	background: rgb(173,216,230);
	background: -moz-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,165,0,1) 40%, rgba(173,216,230,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,165,0,1) 40%, rgba(173,216,230,1) 50%);
	background: linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,165,0,1) 40%, rgba(173,216,230,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ADD8E6",endColorstr="#ADD8E6",GradientType=1);
}

.ltburd {
	background: rgb(173,216,230);
	background: -moz-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,0,0,1) 40%, rgba(173,216,230,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,0,0,1) 40%, rgba(173,216,230,1) 50%);
	background: linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,0,0,1) 40%, rgba(173,216,230,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ADD8E6",endColorstr="#ADD8E6",GradientType=1);
}

.ltbuwh {
	background: rgb(173,216,230);
	background: -moz-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,255,255,1) 40%, rgba(173,216,230,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,255,255,1) 40%, rgba(173,216,230,1) 50%);
	background: linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,255,255,1) 40%, rgba(173,216,230,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ADD8E6",endColorstr="#ADD8E6",GradientType=1);
}

.ltbuye {
	background: rgb(173,216,230);
	background: -moz-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,255,0,1) 40%, rgba(173,216,230,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,255,0,1) 40%, rgba(173,216,230,1) 50%);
	background: linear-gradient(70deg, rgba(173,216,230,1) 30%, rgba(255,255,0,1) 40%, rgba(173,216,230,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ADD8E6",endColorstr="#ADD8E6",GradientType=1);
}

/****************************
*	Light GREEN
*****************************/

.ltgn {
	background: rgb(144,238,144);
}

.ltgnbk {
	background: rgb(144,238,144);
	background: -moz-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(0,0,0,1) 40%, rgba(144,238,144,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(0,0,0,1) 40%, rgba(144,238,144,1) 50%);
	background: linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(0,0,0,1) 40%, rgba(144,238,144,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#90EE90",endColorstr="#90EE90",GradientType=1);
}

.ltgnbg {
	background: rgb(144,238,144);
	background: -moz-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(245,245,220,1) 40%, rgba(144,238,144,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(245,245,220,1) 40%, rgba(144,238,144,1) 50%);
	background: linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(245,245,220,1) 40%, rgba(144,238,144,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#90EE90",endColorstr="#90EE90",GradientType=1);
}

.ltgnbu {
	background: rgb(144,238,144);
	background: -moz-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(0,0,255,1) 40%, rgba(144,238,144,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(0,0,255,1) 40%, rgba(144,238,144,1) 50%);
	background: linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(0,0,255,1) 40%, rgba(144,238,144,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#90EE90",endColorstr="#90EE90",GradientType=1);
}

.ltgnbn {
	background: rgb(144,238,144);
	background: -moz-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(102,51,0,1) 40%, rgba(144,238,144,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(102,51,0,1) 40%, rgba(144,238,144,1) 50%);
	background: linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(102,51,0,1) 40%, rgba(144,238,144,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#90EE90",endColorstr="#90EE90",GradientType=1);
}

.ltgngy {
	background: rgb(144,238,144);
	background: -moz-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(128,128,128,1) 40%, rgba(144,238,144,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(128,128,128,1) 40%, rgba(144,238,144,1) 50%);
	background: linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(128,128,128,1) 40%, rgba(144,238,144,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#90EE90",endColorstr="#90EE90",GradientType=1);
}

.ltgnog {
	background: rgb(144,238,144);
	background: -moz-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,165,0,1) 40%, rgba(144,238,144,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,165,0,1) 40%, rgba(144,238,144,1) 50%);
	background: linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,165,0,1) 40%, rgba(144,238,144,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#90EE90",endColorstr="#90EE90",GradientType=1);
}

.ltgnrd {
	background: rgb(144,238,144);
	background: -moz-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,0,0,1) 40%, rgba(144,238,144,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,0,0,1) 40%, rgba(144,238,144,1) 50%);
	background: linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,0,0,1) 40%, rgba(144,238,144,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#90EE90",endColorstr="#90EE90",GradientType=1);
}

.ltgnwh {
	background: rgb(144,238,144);
	background: -moz-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,255,255,1) 40%, rgba(144,238,144,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,255,255,1) 40%, rgba(144,238,144,1) 50%);
	background: linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,255,255,1) 40%, rgba(144,238,144,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#90EE90",endColorstr="#90EE90",GradientType=1);
}

.ltgnye {
	background: rgb(144,238,144);
	background: -moz-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,255,0,1) 40%, rgba(144,238,144,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,255,0,1) 40%, rgba(144,238,144,1) 50%);
	background: linear-gradient(70deg, rgba(144,238,144,1) 30%, rgba(255,255,0,1) 40%, rgba(144,238,144,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#90EE90",endColorstr="#90EE90",GradientType=1);
}

/****************************
*	 ORANGE
*****************************/

.og, .or {
	background: rgb(255,165,0);
}

.ogbg {
	background: rgb(255,165,0);
	background: -moz-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(245,245,220,1) 40%, rgba(255,165,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(245,245,220,1) 40%, rgba(255,165,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(245,245,220,1) 40%, rgba(255,165,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa500",endColorstr="#ffa500",GradientType=1);
}

.ogbk, .orbk {
	background: rgb(255,165,0);
	background: -moz-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(0,0,0,1) 40%, rgba(255,165,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(0,0,0,1) 40%, rgba(255,165,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(0,0,0,1) 40%, rgba(255,165,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa500",endColorstr="#ffa500",GradientType=1);
}

.ogbn {
	background: rgb(255,165,0);
	background: -moz-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(102,51,0, 1) 40%, rgba(255,165,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(102,51,0,1) 40%, rgba(255,165,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(102,51,0,1) 40%, rgba(255,165,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa500",endColorstr="#ffa500",GradientType=1);
}

.ogbu, .orbu {
	background: rgb(255,165,0);
	background: -moz-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(0,0,255,1) 40%, rgba(255,165,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(0,0,255,1) 40%, rgba(255,165,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(0,0,255,1) 40%, rgba(255,165,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa500",endColorstr="#ffa500",GradientType=1);
}

.oggn, .orgn {
	background: rgb(255,165,0);
	background: -moz-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(0,128,0,1) 40%, rgba(255,165,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(0,128,0,1) 40%, rgba(255,165,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(0,128,0,1) 40%, rgba(255,165,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa500",endColorstr="#ffa500",GradientType=1);
}

.oggy {
	background: rgb(255,165,0);
	background: -moz-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(128,128,128,1) 40%, rgba(255,165,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(128,128,128,1) 40%, rgba(255,165,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(128,128,128,1) 40%, rgba(255,165,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa500",endColorstr="#ffa500",GradientType=1);
}

.ogpk {
	background: rgb(255,165,0);
	background: -moz-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(255,192,203,1) 40%, rgba(255,165,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(255,192,203,1) 40%, rgba(255,165,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(255,192,203,1) 40%, rgba(255,165,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa500",endColorstr="#ffa500",GradientType=1);
}

.ogrd {
	background: rgb(255,165,0);
	background: -moz-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(255,0,0,1) 40%, rgba(255,165,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(255,0,0,1) 40%, rgba(255,165,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(255,0,0,1) 40%, rgba(255,165,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa500",endColorstr="#ffa500",GradientType=1);
}

.ogvt, .ogvi, .orvi {
	background: rgb(255,165,0);
	background: -moz-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(128,0,128,1) 40%, rgba(255,165,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(128,0,128,1) 40%, rgba(255,165,0,1) 50%);
	background: -webkit-linear-gradient(30deg, rgba(255,165,0,1) 30%, rgba(128,0,128,1) 40%, rgba(255,165,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa500",endColorstr="#ffa500",GradientType=1);
}

.ogwh, .orwh {
	background: rgb(255,165,0);
	background: -moz-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(255,255,255,1) 40%, rgba(255,165,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(255,255,255,1) 40%, rgba(255,165,0,1) 50%);
	background: -webkit-linear-gradient(25deg, rgba(255,165,0,1) 30%, rgba(255,255,255,1) 40%, rgba(255,165,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa500",endColorstr="#ffa500",GradientType=1);
}

.ogye {
	background: rgb(255,165,0);
	background: -moz-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(255,255,0,1) 40%, rgba(255,165,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(255,255,0,1) 40%, rgba(255,165,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,165,0,1) 30%, rgba(255,255,0,1) 40%, rgba(255,165,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa500",endColorstr="#ffa500",GradientType=1);
}

/****************************
*	 PINK
*****************************/

.pkbg {
	background: rgb(255,192,203);
	background: -moz-linear-gradient(70deg, rgba(255,192,203,1) 30%,rgba(245,245,220,1) 40%, rgba(255,192,203,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(245,245,220,1) 40%, rgba(255,192,203,1) 50%);
	background: linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(245,245,220,1) 40%, rgba(255,192,203,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc0cb",endColorstr="#ffc0cb",GradientType=1);
}

.pkbr {
	background: rgb(255,192,203);
	background: -moz-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(102,51,0,1) 40%, rgba(255,192,203,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(102,51,0,1) 40%, rgba(255,192,203,1) 50%);
	background: linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(102,51,0,1) 40%, rgba(255,192,203,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc0cb",endColorstr="#ffc0cb",GradientType=1);
}

.pkbu {
	background: rgb(255,192,203);
	background: -moz-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(0,0,255,1) 40%, rgba(255,192,203,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(0,0,255,1) 40%, rgba(255,192,203,1) 50%);
	background: linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(0,0,255,1) 40%, rgba(255,192,203,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc0cb",endColorstr="#ffc0cb",GradientType=1);
}

.pkgn {
	background: rgb(255,192,203);
	background: -moz-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(0,128,0,1) 40%, rgba(255,192,203,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(0,128,0,1) 40%, rgba(255,192,203,1) 50%);
	background: linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(0,128,0,1) 40%, rgba(255,192,203,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc0cb",endColorstr="#ffc0cb",GradientType=1);
}

.pkgy {
	background: rgb(255,192,203);
	background: -moz-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(128,128,128,1) 40%, rgba(255,192,203,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(128,128,128,1) 40%, rgba(255,192,203,1) 50%);
	background: linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(128,128,128,1) 40%, rgba(255,192,203,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc0cb",endColorstr="#ffc0cb",GradientType=1);
}

.pkog {
	background: rgb(255,192,203);
	background: -moz-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(255,165,0,1) 40%, rgba(255,192,203,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(255,165,0,1) 40%, rgba(255,192,203,1) 50%);
	background: linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(255,165,0,1) 40%, rgba(255,192,203,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc0cb",endColorstr="#ffc0cb",GradientType=1);
}

.pkrd {
	background: rgb(255,192,203);
	background: -moz-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(255,0,0,1) 40%, rgba(255,192,203,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(255,0,0,1) 40%, rgba(255,192,203,1) 50%);
	background: linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(255,0,0,1) 40%, rgba(255,192,203,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc0cb",endColorstr="#ffc0cb",GradientType=1);
}

.pkvt {
	background: rgb(255,192,203);
	background: -moz-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(128,0,128,1) 40%, rgba(255,192,203,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(128,0,128,1) 40%, rgba(255,192,203,1) 50%);
	background: linear-gradient(70deg, rgba(255,192,203,1) 30%, rgba(128,0,128,1) 40%, rgba(255,192,203,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc0cb",endColorstr="#ffc0cb",GradientType=1);
}

/****************************
*	 RED
*****************************/

.rd {
	background: rgb(255,0,0);
}

.rdbk {
	background: rgb(255,0,0);
	background: -moz-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(0,0,0,1) 40%, rgba(255,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(0,0,0,1) 40%, rgba(255,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(0,0,0,1) 40%, rgba(255,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000",endColorstr="#ff0000",GradientType=1);
}

.rdbg {
	background: rgb(255,0,0);
	background: -moz-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(245,245,220,1) 40%, rgba(255,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(245,245,220,1) 40%, rgba(255,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(245,245,220,1) 40%, rgba(255,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000",endColorstr="#ff0000",GradientType=1);
}

.rdbn {
	background: rgb(255,0,0);
	background: -moz-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(102,51,0,1) 40%, rgba(255,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(102,51,0,1) 40%, rgba(255,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(102,51,0,1) 40%, rgba(255,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000",endColorstr="#ff0000",GradientType=1);
}

.rdbu {
	background: rgb(255,0,0);
	background: -moz-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(0,0,255,1) 40%, rgba(255,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(0,0,255,1) 40%, rgba(255,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(0,0,255,1) 40%, rgba(255,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000",endColorstr="#ff0000",GradientType=1);
}

.rdgn {
	background: rgb(255,0,0);
	background: -moz-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(0,128,0,1) 40%, rgba(255,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(0,128,0,1) 40%, rgba(255,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(0,128,0,1) 40%, rgba(255,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000",endColorstr="#ff0000",GradientType=1);
}

.rdgy {
	background: rgb(255,0,0);
	background: -moz-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(128,128,128,1) 40%, rgba(255,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(128,128,128,1) 40%, rgba(255,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(128,128,128,1) 40%, rgba(255,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000",endColorstr="#ff0000",GradientType=1);
}

.rdog {
	background: rgb(255,0,0);
	background: -moz-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(255,165,0,1) 40%, rgba(255,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(255,165,0,1) 40%, rgba(255,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(255,165,0,1) 40%, rgba(255,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000",endColorstr="#ff0000",GradientType=1);
}

.rdpk {
	background: rgb(255,0,0);
	background: -moz-linear-gradient(70deg, rgba(255,0,0,1) 55%, rgba(255,192,203,1) 40%, rgba(255,0,0,1) 55%);
	background: -webkit-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(255,192,203,1) 40%, rgba(255,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(255,192,203,1) 40%, rgba(255,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000",endColorstr="#ff0000",GradientType=1);
}

.rdvt {
	background: rgb(255,0,0);
	background: -moz-linear-gradient(69deg, rgba(255,0,0,1) 30%, rgba(128,0,128,1) 40%, rgba(255,0,0,1) 50%);
	background: -webkit-linear-gradient(69deg, rgba(255,0,0,1) 30%, rgba(128,0,128,1) 40%, rgba(255,0,0,1) 50%);
	background: linear-gradient(69deg, rgba(255,0,0,1) 30%, rgba(128,0,128,1) 40%, rgba(255,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000",endColorstr="#ff0000",GradientType=1);
}

.rdwh {
	background: rgb(255,0,0);
	background: -moz-linear-gradient(69deg, rgba(255,0,0,1) 30%, rgba(255,255,255,1) 40%, rgba(255,0,0,1) 50%);
	background: -webkit-linear-gradient(69deg, rgba(255,0,0,1) 30%, rgba(255,255,255,1) 40%, rgba(255,0,0,1) 50%);
	background: linear-gradient(69deg, rgba(255,0,0,1) 30%, rgba(255,255,255,1) 40%, rgba(255,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000",endColorstr="#ff0000",GradientType=1);
}

.rdye {
	background: rgb(255,0,0);
	background: -moz-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(255,255,0,1) 40%, rgba(255,0,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(255,255,0,1) 40%, rgba(255,0,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,0,0,1) 30%, rgba(255,255,0,1) 40%, rgba(255,0,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000",endColorstr="#ff0000",GradientType=1);
}

/****************************
*	 VIOLET
*****************************/

.vt {
	background: rgb(128,0,128);
}

.vtbg {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(245,245,220,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(245,245,220,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(245,245,220,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}

.vtbk {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(0,0,0,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(0,0,0,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(0,0,0,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}

.vtbn {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(102,51,0,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(102,51,0,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(102,51,0,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}

.vtbu {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(0,0,255,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(0,0,255,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(0,0,255,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}

.vtgn {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(0,128,0,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(0,128,0,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(0,128,0,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}

.vtlybu {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(173,216,230,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(173,216,230,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(173,216,230,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}

.vtltgn {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(144,238,144,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(144,238,144,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(144,238,144,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}

.vtog {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(69deg, rgba(128,0,128,1) 30%, rgba(255,165,0,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(69deg, rgba(128,0,128,1) 30%, rgba(255,165,0,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(69deg, rgba(128,0,128,1) 30%, rgba(255,165,0,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}

.vtpk {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(69deg, rgba(128,0,128,1) 30%, rgba(255,192,203,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(69deg, rgba(128,0,128,1) 30%, rgba(255,192,203,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(69deg, rgba(128,0,128,1) 30%, rgba(255,192,203,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}

.vtrd {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(255,0,0,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(255,0,0,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(255,0,0,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}

.vtwh {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(69deg, rgba(128,0,128,1) 30%, rgba(255,255,255,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(69deg, rgba(128,0,128,1) 30%, rgba(255,255,255,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(69deg, rgba(128,0,128,1) 30%, rgba(255,255,255,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}

.vtye {
	background: rgb(128,0,128);
	background: -moz-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(255,255,0,1) 40%, rgba(128,0,128,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(255,255,0,1) 40%, rgba(128,0,128,1) 50%);
	background: linear-gradient(70deg, rgba(128,0,128,1) 30%, rgba(255,255,0,1) 40%, rgba(128,0,128,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#800080",endColorstr="#800080",GradientType=1);
}


/****************************
*	 WHITE
*****************************/

.wh {
	background: rgb(255,255,255);
}

.whbg {
	background: rgb(255,255,255);
	background: -moz-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(245,245,220,1) 40%, rgba(255,255,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(245,245,220,1) 40%, rgba(255,255,255,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(245,245,220,1) 40%, rgba(255,255,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.whbk {
	background: rgb(255,255,255);
	background: -moz-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(0,0,0,1) 40%, rgba(255,255,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(0,0,0,1) 40%, rgba(255,255,255,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(0,0,0,1) 40%, rgba(255,255,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.whbn {
	background: rgb(255,255,255);
	background: -moz-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(102,51,0,1) 40%, rgba(255,255,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(102,51,0,1) 40%, rgba(255,255,255,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(102,51,0,1) 40%, rgba(255,255,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.whbu {
	background: rgb(255,255,255);
	background: -moz-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(0,0,255,1) 40%, rgba(255,255,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(0,0,255,1) 40%, rgba(255,255,255,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(0,0,255,1) 40%, rgba(255,255,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.whgy {
	background: rgb(255,255,255);
	background: -moz-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(128,128,128,1) 40%, rgba(255,255,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(128,128,128,1) 40%, rgba(255,255,255,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(128,128,128,1) 40%, rgba(255,255,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.whltbu {
	background: rgb(255,255,255);
	background: -moz-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(173,216,230,1) 40%, rgba(255,255,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(173,216,230,1) 40%, rgba(255,255,255,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(173,216,230,1) 40%, rgba(255,255,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.whpk {
	background: rgb(255,255,255);
	background: -moz-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,192,203,1) 40%, rgba(255,255,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,192,203,1) 40%, rgba(255,255,255,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,192,203,1) 40%, rgba(255,255,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.whog, .whor {
	background: rgb(255,255,255);
	background: -moz-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,165,0,1) 40%, rgba(255,255,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,165,0,1) 40%, rgba(255,255,255,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,165,0,1) 40%, rgba(255,255,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.whrd {
	background: rgb(255,255,255);
	background: -moz-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,0,0,1) 40%, rgba(255,255,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,0,0,1) 40%, rgba(255,255,255,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,0,0,1) 40%, rgba(255,255,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.whvt {
	background: rgb(255,255,255);
	background: -moz-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(128,0,128,1) 40%, rgba(255,255,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(128,0,128,1) 40%, rgba(255,255,255,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(128,0,128,1) 40%, rgba(255,255,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.whye {
	background: rgb(255,255,255);
	background: -moz-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,255,0,1) 40%, rgba(255,255,255,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,255,0,1) 40%, rgba(255,255,255,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,255,1) 30%, rgba(255,255,0,1) 40%, rgba(255,255,255,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

/****************************
*	 YELLOW
*****************************/

.ye {
	background: rgb(255,255,0);
}

.yebk {
	background: rgb(255,255,0);
	background: -moz-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(0,0,0,1) 40%, rgba(255,255,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(0,0,0,1) 40%, rgba(255,255,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(0,0,0,1) 40%, rgba(255,255,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffff00",endColorstr="#ffff00",GradientType=1);
}

.yebn {
	background: rgb(255,255,0);
	background: -moz-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(102,51,0,1) 40%, rgba(255,255,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(102,51,0,1) 40%, rgba(255,255,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(102,51,0,1) 40%, rgba(255,255,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffff00",endColorstr="#ffff00",GradientType=1);
}

.yebu, .ylbl {
	background: rgb(255,255,0);
	background: -moz-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(0,0,255,1) 40%, rgba(255,255,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(0,0,255,1) 40%, rgba(255,255,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(0,0,255,1) 40%, rgba(255,255,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffff00",endColorstr="#ffff00",GradientType=1);
}

.yegn {
	background: rgb(255,255,0);
	background: -moz-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(0,128,0,1) 40%, rgba(255,255,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(0,128,0,1) 40%, rgba(255,255,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(0,128,0,1) 40%, rgba(255,255,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffff00",endColorstr="#ffff00",GradientType=1);
}

.yegy, .ylgy {
	background: rgb(255,255,0);
	background: -moz-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(128,128,128,1) 40%, rgba(255,255,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(128,128,128,1) 40%, rgba(255,255,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(128,128,128,1) 40%, rgba(255,255,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffff00",endColorstr="#ffff00",GradientType=1);
}

.yeltbu {
	background: rgb(255,255,0);
	background: -moz-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(173,216,230,1) 40%, rgba(255,255,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(173,216,230,1) 40%, rgba(255,255,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(173,216,230,1) 40%, rgba(255,255,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffff00",endColorstr="#ffff00",GradientType=1);
}

.yeog {
	background: rgb(255,255,0);
	background: -moz-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,165,0,1) 40%, rgba(255,255,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,165,0,1) 40%, rgba(255,255,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,165,0,1) 40%, rgba(255,255,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffff00",endColorstr="#ffff00",GradientType=1);
}

.yepk {
	background: rgb(255,255,0);
	background: -moz-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,192,203,1) 40%, rgba(255,255,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,192,203,1) 40%, rgba(255,255,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,192,203,1) 40%, rgba(255,255,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffff00",endColorstr="#ffff00",GradientType=1);
}

.yerd {
	background: rgb(255,255,0);
	background: -moz-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,0,0,1) 40%, rgba(255,255,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,0,0,1) 40%, rgba(255,255,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,0,0,1) 40%, rgba(255,255,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffff00",endColorstr="#ffff00",GradientType=1);
}

.yewh {
	background: rgb(255,255,0);
	background: -moz-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,255,255,1) 40%, rgba(255,255,0,1) 50%);
	background: -webkit-linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,255,255,1) 40%, rgba(255,255,0,1) 50%);
	background: linear-gradient(70deg, rgba(255,255,0,1) 30%, rgba(255,255,255,1) 40%, rgba(255,255,0,1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffff00",endColorstr="#ffff00",GradientType=1);
}
