@import './variables';
@import './mixins';

body {
  font-size: $font-size;
  font-family: $font-stack;
}

.title {
  background-color: $dark-color;
  color: $light-font;
  line-height: 1.3em;
  padding: 10px 20px;
  font-size: 20px;
}

.comp-data, .help-info-box {
  background-color: $white-color;
  padding: 20px 20px 0;
}

.pane {
  @include for-size(desktop-up){
    height: calc(51vh - 10px);
  }
  @include for-size(big-desktop-up){
    height: calc(60vh - 10px);
  }
  padding: 0 10px 0 20px;
}

.pane h4 {
  font-size: 18px;
  // margin-bottom: -10px;
  border-bottom: 1px solid $medium-color;
  font-weight: normal;
  margin-top: 0;
  text-transform: uppercase;
}

table {
  font-size: 18px;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

td, th {
  border: 1px solid $dark-color;
  padding: 0.5rem;
}

th {
  background-color: $light-color;
  text-align: center;
  padding: 10px 5px;
}

tr {
  text-align: center;
}
//SVG styles
g#Background {
  // cursor: grab;
}
g#Text text {
  cursor: default;
}
//hides illustrator/svg related class colors on drawing
#Components circle, #Components rect, #Wires line, #Wires polyline {
  fill: transparent !important;
}

#Components circle:hover, #Components rect:hover, #Wires g:hover line, #Wires g:hover polyline {
  stroke: $secondary-color;
  stroke-width: 6px !important;
  opacity: 1;
  cursor: pointer;
}

circle.test, rect.test, g.test line, g.test polyline {

  stroke-width: 5px !important;
  opacity: 1;
}

g.test .cls-56 {
  stroke-dasharray: 5 9;
}

circle.test, rect.test {
  stroke: $primary-color;
  stroke-width: 5px !important;
  opacity: 1;
}

g line, g polyline { opacity: 1;}
g.dimmed line, g.dimmed polyline
 {
   stroke: $light-color;
   stroke-width: 1.75px;
   opacity: 0.95;
}
path:hover, circle:hover {
 fill: #c8c04c !important;
}
path.highlight, circle.highlight {
 fill: #c8c04c !important;
}
svg {
  display: flex;
  //flex: 0 0 auto;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  max-width: 1200px;
  height: 90%;

}

.basic-plus svg, .advanced-plus svg {
  display: flex;
  position: absolute;
  height: auto;
  width: 61.5%;
  //top: 9%;
  z-index: 1000;
  @include for-size(desktop-up){
    top: 10.5%;
      width: 56.5%;
    }
  @include for-size(big-desktop-up){
          top: 9%;
            width: 61.5%;
    }
}

.advanced-plus svg {
  //top: 4.5%;
  @include for-size(desktop-up){
        top: 6%;
    }
  @include for-size(big-desktop-up){
      top: 4.5%;
    }
  // display: flex;
  // height: auto;
  // //width: 62%;
  // // max-height: 66%;
}
// .fullWidth {
//   max-width: 95vw;
//   transition: max-width .75s ease-in ;
// }

.basic-plus.fullWidth svg, .advanced-plus.fullWidth svg {
  height: auto;
  width: 75%;
  top: -27px;
  left: 17.5%;
}

.play-button {
    height: 60px;
    width: 60px;
    display: inline-block;
    text-align: center;
    font-weight: 900;
    cursor: pointer;
    font-size: 60px;
    line-height: 1em;
    background: rgba($dark-color,1);
    color: $dark-color;
}
